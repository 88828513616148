<template>
	<div style="border: 1px solid #ccc;">
		<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
		<Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
			@onCreated="onCreated" @onChange="getValue" />
	</div>
</template>

<script>
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import {
		upload
	} from '@/api/admin'
	import '@wangeditor/editor/dist/css/style.css'

	const uploadConfig = (type) => {
		const config = {
			server: upload,
			fieldName: 'file',
			maxFileSize: 10 * 1024 * 1024,
			timeout: 100 * 1000,
			maxNumberOfFiles: 1,
			// onBeforeUpload:null,// 上传之前触发
			// onProgress:null,// 上传进度的回调函数
			// onSuccess:null,// 单个文件上传成功之后
			// onFailed:null,// 单个文件上传失败
			// onError:null,// 上传错误，或者触发 timeout 超时
			customInsert: (res, insertFn) => {
				if (res.result) {
					insertFn(res.data)
				}
			}
		}
		if (type == 'img') {

		} else {

		}
		return config
	}
	export default {
		props: {
			value: {
				type: String,
				default: ''
			}
		},
		components: {
			Editor,
			Toolbar
		},
		data() {
			return {
				editor: null,
				html: '',
				toolbarConfig: {},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: uploadConfig('img'),
						uploadVideo: uploadConfig('video'),
					}
				},
				mode: 'default', // or 'simple'
			}
		},
		watch: {
			value() {
				this.html = this.value
			}
		},
		
		methods: {
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			},
			getValue(e) {
				this.$emit('input', e.getHtml())
				this.$emit('change', {
					html: e.getHtml(),
					text: e.getText()
				})
				// return this.editor.getHtml()
			}
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
	}
</script>
import store from '@/store'
import router from '@/router'
import {
	isArray
} from './validate'

export function isJSON(str) {
	try {
		JSON.parse(str);
		return true;
	} catch (e) {
		return false;
	}
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
	router.options.isAddDynamicMenuRoutes = false
	sessionStorage.clear()
	store.commit('resetStore')
}
/**
 * 获取登录信息
 */
export function getLoginInfo() {
	return sessionStorage.getItem('token')
}
/**
 * 设置登录信息
 */
export function setLoginInfo(token) {
	return sessionStorage.setItem('token', token)
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
	if (isArray(key)) {
		return key && key.length
	} else {
		if (key == 'estate:save' && store.state.user.id != 1) {
			return false
		}
		return (store.state.common.permissions || []).indexOf(key) !== -1 || false
	}
}

/**
 * 获取uuid
 */
export function getUUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
		return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
	})
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'pid') {
	var res = []
	var temp = {}
	for (var i = 0; i < data.length; i++) {
		temp[data[i][id]] = data[i]
	}
	for (var k = 0; k < data.length; k++) {
		if (!temp[data[k][pid]] || data[k][id] === data[k][pid]) {
			res.push(data[k])
			continue
		}
		if (!temp[data[k][pid]]['children']) {
			temp[data[k][pid]]['children'] = []
		}
		temp[data[k][pid]]['children'].push(data[k])
		data[k]['_level'] = (temp[data[k][pid]]._level || 0) + 1
	}
	return res
}

// 递归
// 1. 结束条件 treeData为空，返回[]
// 2. 结束条件 treeData不为空 返回[当前元素].concat(transfer(子元素))
// 需要实现transfer函数, 输入树形结构, 输出结果为一个一维数组:arrayData
export function transferData(treeData) {
	const data = JSON.parse(JSON.stringify(treeData)),
		res = [];
	const dfs = (tree) => {
		tree.forEach((item) => {
			if (item.list) {
				dfs(item.list);
				delete item.list;
			}
			res.push(item);
		});
	};
	dfs(data);
	return res;
}
export function setTheme(color) {
	// document.documentElement 是全局变量时
	const el = document.documentElement
	// const el = document.getElementById('xxx')
	console.log(color, '主题颜色')
	// 获取 css 变量
	getComputedStyle(el).getPropertyValue(`--el-color-primary`)

	// 设置 css 变量
	el.style.setProperty('--el-color-primary', color)
}

export function clearUrlParam(_this, event) {
	let urlParam = _this.serialize()
	let splitStr = urlParam.split('&')
	var newStr = ''
	var label = ''
	for (var i = 0; i < splitStr.length; i++) {
		let splitStrArray = splitStr[i].split('=')
		if (splitStrArray[1].length > 0) {
			newStr += unescape(splitStrArray[0] + '=' + splitStrArray[1] + '&')
		}
	}
	if (newStr != '') {
		label = '?'
		newStr = newStr.substring(0, newStr.length - 1)
	}
	event.preventDefault()
	return _this.attr('action') + label + newStr
}

// 进入全屏
export function launchFullscreen(element) {
	element = element || document.documentElement
	if (element.requestFullscreen) {
		element.requestFullscreen();
	} else if (element.mozRequestFullScreen) {
		//不同浏览器之间需要添加前缀
		element.mozRequestFullScreen();
	} else if (element.msRequestFullscreen) {
		element.msRequestFullscreen();
	} else if (element.webkitRequestFullscreen) {
		element.webkitRequestFullScreen();
	}
}

// 退出全屏
export function exitFullscreen() {
	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (document.msExitFullscreen) {
		document.msExitFullscreen();
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen();
	} else if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen();
	}
}

export function objectToQueryString(obj) {
	if (sessionStorage.getItem('relevancy')) {
		var relevancy = JSON.parse(sessionStorage.getItem('relevancy'))
		var id = relevancy.relevancyId || ''
		var parkId = relevancy.parkId || ''
		if (relevancy.relevancyType == 1) {
			obj.estateId = obj.estateId || id || ''
		}
		if (relevancy.relevancyType == 2) {
			obj.parkId = obj.parkId || id || ''
		}
		if (relevancy.relevancyType == 3) {
			obj.sentryId = obj.sentryId || id || ''
			obj.parkId = obj.parkId || parkId || ''
		}
	}
	return Object.keys(obj).map(function(key) {
		return ''.concat(encodeURIComponent(key), '=').concat(encodeURIComponent(obj[key]))
	}).join('&')
}

/**
 * 十六进制color颜色/RGBA/RGB，改变透明度
 * @param {*} thisColor #555 rgba(85,85,85,0.6) rgb(85,85,85)
 * @param {*} thisOpacity 0.7
 * @returns rgba(85,85,85,0.7)
 */
export function getOpacityColor(thisColor, thisOpacity) {
	var theColor = thisColor.toLowerCase();
	//十六进制颜色值的正则表达式
	var r = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
	// 如果是16进制颜色
	if (theColor && r.test(theColor)) {
		if (theColor.length === 4) {
			var sColorNew = '#';
			for (var i = 1; i < 4; i += 1) {
				sColorNew += theColor.slice(i, i + 1).concat(theColor.slice(i, i + 1));
			}
			theColor = sColorNew;
		}
		//处理六位的颜色值
		var sColorChange = [];
		for (var j = 1; j < 7; j += 2) {
			sColorChange.push(parseInt('0x' + theColor.slice(j, j + 2)));
		}
		return 'rgba(' + sColorChange.join(',') + ',' + thisOpacity + ')';
	}
	// 如果是rgba或者rgb
	if (theColor.startsWith('rgb')) {
		let numbers = theColor.match(/(\d(\.\d+)?)+/g);
		numbers = numbers.slice(0, 3).concat(thisOpacity);
		return 'rgba(' + numbers.join(',') + ')';
	}
	return theColor;
}

/**
 * 日期类型格式化方法
 * @param fmt 需要格式化成的格式
 * @returns {*|string}
 */
Date.prototype.format = function(fmt) {

	fmt = fmt || "yyyy-MM-dd";
	let o = {
		"M+": this.getMonth() + 1, //月份
		"d+": this.getDate(), //日
		"h+": this.getHours(), //小时
		"m+": this.getMinutes(), //分
		"s+": this.getSeconds(), //秒
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度
		"S": this.getMilliseconds() //毫秒
	};

	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	}

	for (const k in o) {
		if (new RegExp("(" + k + ")").test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k])
				.length)));
		}
	}

	return fmt;
};
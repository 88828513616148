const obj = {}
export default {
	bind(el, binding) {
		obj.el = el
		obj.binding = binding
		// 水印文字，父元素，画布宽度，画布高度，字体，文字颜色，画布横坐标
		function addWaterMarker(str, parentNode, width, height, font, textColor, fillTextX = '10') {
			// 检查父元素是否包含子元素
			const elementContains = (parent, child) => parent !== child && parent.contains(child);
			const flag = elementContains(parentNode, document.querySelector('canvas'));

			// 防止重复创建
			if (!flag) {
				let can = document.createElement('canvas');
				parentNode.appendChild(can);
				can.width = width || 300;
				can.height = height || 150;
				can.style.display = 'none';
				let cans = can.getContext('2d');
				cans.rotate(-20 * Math.PI / 200);
				cans.font = font || "13px Microsoft Yahei";
				cans.fillStyle = textColor || "rgba(0,0,0,0.5)";
				// cans.fillStyle = textColor || "#DDDDDD";
				cans.textAlign = 'left';
				cans.textBaseline = 'Middle';
				cans.fillText('版权所有：' + str, fillTextX, can.height);
				// 设置背景图（整个项目中都添加水印建议使用此方法）
				// parentNode.style.backgroundImage = "url(" + can.toDataURL("image/png") + ")";

				// 创建div 定位覆盖（某个元素，如图片添加水印建议使用此方法）
				let div = document.createElement('div');
				div.id = 'watermark';
				div.style.pointerEvents = 'none';
				div.style.top = '0';
				div.style.left = '0';
				div.style.position = 'absolute';
				div.style.opacity = '0.7';
				div.style.zIndex = '10000';
				div.style.width = '100%';
				div.style.height = '100%';
				div.style.background = 'url(' + can.toDataURL('image/png') + ')';
				parentNode.appendChild(div);
			}
		}
		if (binding.value.NAME && binding.value.wartermark) {
			addWaterMarker(binding.value.NAME, el, binding.value.width, binding.value.height, binding.value.font, binding
				.value.textColor, binding.value.fillTextX)

			// 此方法是防止用户通过 开发者工具 修改样式/或直接删除 祛除水印
			const observer = new MutationObserver(() => {
				const wmInstance = document.querySelector('#watermark') // 获取到你的水印dom
				if (!wmInstance) {
					console.log('水印被删除了！！！')
					window.location.reload();
					return
				}
			})
			observer.observe(document.body, {
				childList: true, // 观察目标子节点的变化，是否有添加或者删除
				attributes: true, // 观察属性变动
				subtree: true // 观察后代节点，默认为 false
			})
		}
	}
}
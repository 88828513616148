export default {
	namespaced: true,
	state: {
		// 侧边栏, 菜单
		menuList: [],
		// 头部菜单
		menuItem: {},
		menuActiveName: '',
		permissions: [],
		tabsMenuList: [],
	},
	mutations: {
		updateTabsMenuList(state, list) {
			state.tabsMenuList = list
		},
		updateMenuList(state, list) {
			state.menuList = list
		},
		updateMenuItem(state, Item) {
			state.menuItem = Item
		},
		updateMenuActiveName(state, name) {
			state.menuActiveName = name
		},
		updatePermissions(state, permissions) {
			state.permissions = permissions
		},
	}
}
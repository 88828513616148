export default {
	namespaced: true,
	state: {
		id: 0,
		name: '',
		nickName: '',
	},
	mutations: {
		updateId(state, id) {
			state.id = id
		},
		updateName(state, name) {
			state.name = name
		},
		updateNickName(state, nickName) {
			state.nickName = nickName
		}
	}
}
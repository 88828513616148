/**
 * Created by sxw at 2023/11/9
 * @param {Object} data
 * get  adornParams()
 * post adornData()
 */
import http from '@/api'

export function list(data) {
	delete data.total
	return http({
		url: http.adornUrl("/park/lane/list"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function info(id) {
	return http({
		url: http.adornUrl('/park/lane/info/' + id),
		method: "get",
	})
}

export function saveOrUpdate(data) {
	return http({
		url: http.adornUrl(`/park/lane/${!data.id?'save':'update'}`),
		method: "post",
		data: http.adornData(data),
	})
}

export function del(data) {
	return http({
		url: http.adornUrl("/park/lane/delete/" + data),
		method: "post",
	})
}

// 获取车道下的收费类型配置
export function laneCarTypeList(data) {
	delete data.total
	return http({
		url: http.adornUrl('/car/tcartype/laneList'),
		method: "get",
		params: http.adornParams(data),
	})
}


// 批量或者单个设置车道通行车辆配置表
export function setTheVehicleTypeConfiguration(data) {
	return http({
		url: http.adornUrl("/park/area/setTheVehicleTypeConfiguration"),
		method: "post",
		data: http.adornData(data, false),
	})
}

export function etcInfo(id) {
	return http({
		url: http.adornUrl('/equ/etc/getEtc/' + id),
		method: "get",
	})
}

export function etcSave(data) {
	return http({
		url: http.adornUrl(`/equ/etc/saveorupdate`),
		method: "post",
		data: http.adornData(data),
	})
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui';
import request from '@/api' // api: https://github.com/axios/axios
import dataV from '@jiaminghi/data-view' //api:http://datav.jiaminghi.com/guide/
import cloneDeep from 'lodash/cloneDeep'
import "@/directive"; //directive
import './icons' // icon
import '@/assets/icon/iconfont.css'

import 'normalize.css';
// import 'element-ui/lib/theme-chalk/index.css';
import './assets/scss/index.scss'
import './components' // 自定义组件

import Updater from "@/utils/autoUpdate.js"

//前端重新部署通知用户刷新网页
if (process.env.NODE_ENV != 'development') {
	// const AutoUpdate = new Updater()
	// AutoUpdate.on('update', () => {
	// 	setTimeout(async () => {
	// 		const result = confirm('当前网站有更新，请点击确定刷新页面体验');
	// 		if (result) {
	// 			location.reload(true);
	// 		}
	// 	}, 500)
	// })
}
import {
	isAuth
} from '@/utils'
import mixin from '@/mixins'

Vue.use(Element, {
	size: sessionStorage.getItem('ElementSize') || 'mini',
	zIndex: 3000
});
Vue.use(dataV)
// if (process.env.NODE_ENV === 'development') {
// 	require('../mock')
// }else{
// 	require('../mock')//线上也先用着
// }

Vue.filter('dateFormat', (dateStr, pattern) => {
	//得到特定的时间
	var date = new Date(dateStr);
	var year = date.getFullYear();
	//ES6语法填充：var month = (date.getMonth()+1).toString().padStart(2, '0');
	var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
	var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	var hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
	var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
	var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

	if (pattern && pattern.toLowerCase() === 'yyyy-mm-dd') {
		// 用模板字符串拼接
		return `${year}-${month}-${day}`;
	} else if (pattern && pattern.toLowerCase() === 'hh:mm:ss') {
		return `${hh}:${mm}:${ss}`;
	} else {
		return `${year}-${month}-${day} ${hh}:${mm}:${ss}`;
	}
})

Vue.config.productionTip = false
Vue.prototype.$http = request // ajax请求方法
Vue.prototype.isAuth = isAuth // 权限方法

// 保存整站vuex本地储存初始状态
Vue.prototype['storeState'] = cloneDeep(store.state)
// window.SITE_CONFIG['storeState'] = cloneDeep(store.state)

// 全局混入
Vue.mixin(mixin)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
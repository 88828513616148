import Vue from 'vue'
import XwDialog from '@/components/XwDialog' //自定义弹出层对话框
import XwTable from '@/components/XwTable' //自定义表格
import Time from '@/components/Time'
import XwSelect from '@/components/XwSelect'
import XwUpload from '@/components/XwUpload'
import xwPreview from '@/components/xwPreview'
import elTables from '@/components/table' //自定义表格悬浮展示
import XwCarKeyboard from '@/components/XwCarKeyboard'
import XwEditor from '@/components/XwEditor'

Vue.component('XwTime', Time)
Vue.component('XwDialog', XwDialog)
Vue.component('XwTable', XwTable)
Vue.component('XwSelect', XwSelect)
Vue.component('XwUpload', XwUpload)
Vue.component('xwPreview', xwPreview)
Vue.component('elTables', elTables)
Vue.component('XwCarKeyboard', XwCarKeyboard)
Vue.component('XwEditor', XwEditor)
/**
 * Created by sxw at 2023/11/27
 * @param {Object} data
 * get  adornParams()
 * post adornData()
 */
import {
	saveAs
} from 'file-saver';
import {
	Message
} from "element-ui"
import http from '@/api'

export const upload = http.adornUrl("/car/tcar/upload")

export function list(data) {
	delete data.total
	return http({
		url: http.adornUrl("/car/tcar/list"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function getObjectList(data) {
	return http({
		url: http.adornUrl("/sys/data/getUserObject"),
		method: "get",
		params: http.adornParams(data),
	})
}

// 获取车辆的最后一个订单
export function getCarOrderEnd(data) {
	return http({
		url: http.adornUrl("/car/tcar/getCarOrderEnd"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function info(id) {
	return http({
		url: http.adornUrl('/car/tcar/info/' + id),
		method: "get",
	})
}

export function saveOrUpdate(data) {
	return http({
		url: http.adornUrl(`/car/tcar/${!data.id?'save':'update'}`),
		method: "post",
		data: http.adornData(data),
	})
}

export function batchUpdate(data) {
	return http({
		url: http.adornUrl(`/car/tcar/batchUpdate`),
		method: "post",
		data: http.adornData(data),
	})
}

export function del(id) {
	return http({
		url: http.adornUrl("/car/tcar/delete/" + id),
		method: "post",
	})
}

export function getCarPassLog(data) {
	delete data.total
	return http({
		url: http.adornUrl("/car/tcar/getCarLog"),
		method: "get",
		params: http.adornParams(data),
	})
}

export function getCarLog(id) {
	return http({
		url: http.adornUrl("/car/tcar/getCarUpdateInfoLog/"+id),
		method: "get",
	})
}

export function getCardList(data) {
	return http({
		url: http.adornUrl("/car/tcar/cardUpgradeList"),
		method: "get",
		params: http.adornParams(data)
	})
}

export function payCard(data) {
	return http({
		url: http.adornUrl("/car/tcar/cardUpgrade"),
		method: 'post',
		data: http.adornData(data),
	})
}

export function exprotExcel(data, template = '') {
	http({
		url: http.adornUrl('/car/tcar/carExportExcel'),
		method: 'post',
		responseType: 'blob',
		params: http.adornParams({
			...data,
			template
		})
	}).then(res => {
		let typeCount = {
			type: "text/xml",
		};
		let blob = new Blob([res.data], typeCount);
		if (res.data.type != typeCount.type) {
			// 说明是普通对象数据，后台转换失败
			const fileReader = new FileReader()
			fileReader.readAsText(blob, 'utf-8')

			fileReader.onload = function() {
				let msg = JSON.parse(fileReader.result).msg;
				return Message.error(msg);
			}
		} else {
			console.log(res)
			Message({
				message: '下载成功,导出中···',
				type: "success",
				duration: 1000,
				onClose: () => {
					saveAs(blob, decodeURIComponent(res.headers['content-disposition'].split(';')[1].split('=')[
						1]));
				},
			})
		}
	}).catch(err => {
		console.log(1567, err)
		Message.error('下载失败')
	})
}
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import merge from 'lodash/merge'
import {
	clearLoginInfo,
	getLoginInfo
} from '@/utils'
import {
	Message
} from "element-ui"
const http = axios.create({
	timeout: 1000 * 300,
	withCredentials: true,
	headers: {
		log: true,
		'Content-Type': 'application/json; charset=utf-8'
	}
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
	config.headers['token'] = getLoginInfo()
	// if (['post', 'POST'].includes(config.method) && !config.isJson) {
	// 	config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8'
	// }
	return config
}, error => {
	return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
	if (response.data && response.data.code === 401) { // 401, token失效
		clearLoginInfo()
		router.push({
			name: 'login'
		})
	}
	return response
}, error => {
	return Promise.reject(error)
})

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName, isToken = false) => {
	const adornUrl = process.env.VUE_APP_BASE_API + actionName
	return adornUrl + (isToken ? '?token=' + getLoginInfo() : '')
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
	var defaults = {
		't': new Date().getTime()
	}
	return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = false, contentType = 'json') => {
	var defaults = {
		't': new Date().getTime()
	}
	data = openDefultdata ? merge(defaults, data) : data
	return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}
// 下载
http.down = ({
	data,
	name
}) => {
	try {
		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = URL.createObjectURL(new Blob([escape(data)], {
			type: 'application/json'
		}));
		link.setAttribute('download', decodeURI(escape(name)));
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		Message({
			message: '下载成功,导出中···',
			type: "success",
			duration: 1000,
			onClose: () => {

				// saveAs(resultBlob, data.javaFileName + '.java');
			},
		})
	} catch (e) {
		console.log(e)
		Message.error('下载失败')
		//TODO handle the exception
	}
}

export default http
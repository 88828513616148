/**
 * 前端重新部署通知用户刷新网页
 */

class Updater {
	oldVersion = ''; // 存储第一次值也就是script 的hash 信息
	newVersion = ''; // 获取新的值 也就是新的script 的hash信息
	dispatch = {}; // 小型发布订阅通知用户更新了

	constructor() {
		this.oldVersion = ''
		this.newVersion = '';
		this.dispatch = {};
		this.init(); // 初始化
		this.timing();
	}

	async init() {
		this.oldVersion = await this.getVersion()
	};

	async getVersion() {
		const version = await fetch('/version.json?t=' + new Date().getTime()).then((response) => response.json()).then(
			res => res.version)
		return version
	};

	//发布订阅通知
	on(key, fn) {
		(this.dispatch[key] || (this.dispatch[key] = [])).push(fn)
		return this;
	}

	compare(oldVersion, newVersion) {
		// console.log('旧版本：', oldVersion, '----新版本：', newVersion, oldVersion == newVersion)
		if (oldVersion == newVersion) {
			// this.dispatch['no-update'].forEach(fn => {
			//     fn();
			// })
		} else {
			// 否则通知更新
			this.dispatch['update'].forEach(fn => {
				fn();
			})
		}
	};

	async timing() {
		setInterval(async () => {
			const newVersion = await this.getVersion();
			this.compare(this.oldVersion, newVersion)
			//这边给的是默认值15000,也可以自定义秒数
		}, 60000);
	};
}

export default Updater;
/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
	return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
	return /^1[3,4,5,6,7,8,9][0-9]{9}$/.test(s)
	return /^1[0-9]{10}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
	return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
	return /^http[s]?:\/\/.*/.test(s)
}


/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path)
}
/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
	if (typeof str === 'string' || str instanceof String) {
		return true
	}
	return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
	if (typeof Array.isArray === 'undefined') {
		return Object.prototype.toString.call(arg) === '[object Array]'
	}
	return Array.isArray(arg)
}


export function isNotEmpty(value) {
	// 首先排除null和undefined  
	if (value == null) {
		return false;
	}

	// 对于数组和字符串，空的情况就是长度为0  
	if (typeof value === 'string' || Array.isArray(value)) {
		return value.length > 0;
	}

	// 对于对象，判断它是否没有可枚举的属性  
	if (typeof value === 'object') {
		return Object.keys(value).length > 0;
	}

	// 对于数值，判断它是否不是0、NaN或者非无穷大且非无穷小  
	if (typeof value === 'number') {
		return !Number.isNaN(value) && isFinite(value);
	}

	// 对于布尔值，只认为true是非空的  
	if (typeof value === 'boolean') {
		return value;
	}

	// 其他情况（如函数、Symbol等），我们都认为它是非空的  
	return true;
}